




























































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { NumberFormatResult } from "vue-i18n";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      singleSelect: false,
      selected: [],
      expanded: [],
      headers: [
        {
          value: "priority",
          text: root.$tc("layout.misc.priority"),
          groupable: false,
          sortable: false,
        },
        {
          value: "name",
          text: root.$tc("layout.misc.name"),
          groupable: false,
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          groupable: false,
          align: "end",
          width: 1,
          sortable: false,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 400,
        groupBy: ["name"],
      },
      deleteGroup: false,
      success: false,
      error: "",
      groups: [] as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/partner/group`, {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { partnerGroups, total } }) => {
          state.empty = false;
          state.items = partnerGroups;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    watch(() => state.options, fetchData, { deep: true });

    const deleteGroup = (item: {
      id: string;
      partnerGroup: { id: string };
    }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .delete(`/partner/group/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.deleteGroup = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const groupState = (item: {
      id: string;
      name: string;
      state: boolean;
      numberOfPartnersInRow: number;
    }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: item.name,
        state: item.state ? false : true,
        numberOfPartnersInRow: item.numberOfPartnersInRow,
      };

      state.loading = true;

      axiosInstance
        .put(`/partner/group/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const deletePartner = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/partner/assign/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const partnerState = (item: {
      id: string;
      scale: NumberFormatResult;
      state: boolean;
      partner: { id: string };
      partnerGroup: { id: string };
    }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        partnerGroupId: undefined,
        scale: item.scale,
        state: item.state ? false : true,
      };

      state.loading = true;

      axiosInstance
        .put(`partner/${item.partner.id}/assign/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      fetchData,
      deleteGroup,
      deletePartner,
      groupState,
      partnerState,
    };
  },
});
