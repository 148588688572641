var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-partner-list"},[_c('v-list',_vm._l((_vm.state.items),function(group){return _c('v-list-item-group',{key:group.id},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4 mt-2 pa-4",staticStyle:{"background-color":"lightgray"}},[_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(group.priority)+".")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.displayOrder')))])])],1),_c('div',{staticClass:"text-uppercase black--text flex-grow-1"},[_vm._v(_vm._s(group.name))]),_c('div',{staticClass:"mr-5"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-black"},'span',attrs,false),on),[_vm._v(_vm._s(group.numberOfPartnersInRow))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.partnersInRow')))])])],1),_c('div',{staticClass:"mr-8"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(group.state)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[(group.state)?_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.groupEnabled')))]):_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.groupDisabled')))])])],1),_c('div',{staticClass:"justify-end"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.event.website.partner.group.edit', params: { pgid: group.id }}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),(group.state)?_c('v-list-item',{on:{"click":function($event){return _vm.groupState(group)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOff')))])],1):_c('v-list-item',{on:{"click":function($event){return _vm.groupState(group)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOn')))])],1),_c('v-dialog',{attrs:{"max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(group.deleteDialog),callback:function ($$v) {_vm.$set(group, "deleteDialog", $$v)},expression:"group.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){group.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteGroup(group)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)],1)]),_vm._l((group.eventPartners),function(partner,$index){return _c('v-list-item',{key:partner.id,staticClass:"mt-2"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4 mt-2",staticStyle:{"width":"100%"}},[_c('v-list-item-title',[_vm._v(_vm._s($index + 1)+". "+_vm._s(partner.partner.name))]),_c('div',{staticClass:"mr-5"},[_c('a',{attrs:{"href":partner.partner.website,"target":"_blank"}},[_vm._v(_vm._s(partner.partner.website))])]),_c('div',{staticClass:"mr-8"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(partner.state)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-check")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[(partner.state)?_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.list.partnerEnabled')))]):_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.webiste.config.partners.list.partnerDisabled')))])])],1),_c('div',{staticClass:"justify-end"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'panel.event.website.partner.single.edit', params: { spid: partner.id }}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),(partner.state)?_c('v-list-item',{on:{"click":function($event){return _vm.partnerState(partner)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOff')))])],1):_c('v-list-item',{on:{"click":function($event){return _vm.partnerState(partner)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.turnOn')))])],1),_c('v-dialog',{attrs:{"max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(partner.deleteDialog),callback:function ($$v) {_vm.$set(partner, "deleteDialog", $$v)},expression:"partner.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){partner.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deletePartner(partner)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)],1)],1)])})],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }